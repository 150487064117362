<template>
  <StoryEdit />
</template>

<script>
import StoryEdit from '@/components/Story/StoryEdit';

export default {
  name: 'StoryCreate',
  components: { StoryEdit },
};
</script>

<style scoped>
</style>
